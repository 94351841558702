import type { InstrumentProps } from '../../components/Helper/datatypes';
import Card from '../../components/UI/Card/Card';
import classes from './InstrumentItem.module.css';

const InstrumentItem = (props: InstrumentProps) => {
    const { name } = props;
    const { description } = props;
    // const {cH1_times} = props;
    // const {id} = props;

    // const changeGateTimesHandler = () => {

    // };

    return (
        <Card hover>
            <li className={classes.instrument}>
                <div>
                    <h3> {name} </h3>
                    <p className={classes.description}>{description}</p>
                    {/* <div className={classes.formcontrol}>
                        <label htmlFor="Ch1">Gate times (channel 1)
                        <input
                            type="text"
                            id="Ch1"
                            value={cH1_times}  
                            onChange={changeGateTimesHandler}   
                        /> 
                       </label>
                    </div>                     */}
                </div>
            </li>
        </Card>
    );
};

export default InstrumentItem;
