import type { InstrumentProps, InstrumentPropsArr } from '../../components/Helper/datatypes';
import InstrumentItem from './InstrumentItem';
import classes from './InstrumentsList.module.css';

const InstrumentsList = (props: InstrumentPropsArr) => {
    const { instruments } = props;

    const instrumentsList = instruments.map((instrument: InstrumentProps) => (
        <InstrumentItem
            key={instrument.id}
            id={instrument.id}
            name={instrument.name}
            description={instrument.description}
            waterTableLayer={instrument.waterTableLayer}
        />
    ));

    return (
        <section className={classes.instruments}>
            <ul>{instrumentsList}</ul>
        </section>
    );
};

export default InstrumentsList;
