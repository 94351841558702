import { useState, useEffect, useCallback } from 'react';

import type { InstrumentProps } from '../../components/Helper/datatypes';
import { getInstruments } from '../../components/Helper/getInstruments';
import Header from './Header';
import InstrumentsList from './InstrumentsList';
// import SubHeader from './SubHeader';

export const Instruments = () => {
    const [instruments, setInstruments] = useState<InstrumentProps[]>([]);

    const fetchInstrumentsHandler = useCallback(async () => {
        try {
            const fetchedInstruments = await getInstruments();
            setInstruments(fetchedInstruments);
        } catch (error) {
            // setErr(error.message);
        }
    }, []);

    useEffect(() => {
        fetchInstrumentsHandler();
    }, []);

    return (
        <>
            <Header />
            {/* <SubHeader header="Instruments" /> */}
            <InstrumentsList instruments={instruments} />
        </>
    );
};
