import type { DropDownProps } from '../../components/Helper/datatypes';
import DropDown from '../dataprofile/DropDown';
import classes from './SubHeader.module.css';

const SubHeader = (props: DropDownProps) => {
    // const { header } = props;

    const { onSelectedValueChange } = props;
    const { dropDownItems } = props;
    return (
        // <section className={classes.subheader}>
        //     <h2>{header}</h2>
        // </section>
        <section className={classes.subheader}>
            <h2>
                <DropDown
                    onSelectedValueChange={onSelectedValueChange}
                    dropDownItems={dropDownItems}
                />
            </h2>
        </section>
    );
};

export default SubHeader;
