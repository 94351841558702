import type { OneDateProps } from '../../components/Helper/datatypes';
import classes from './OneDatePicker.module.css';

const OneDatePicker = (props: OneDateProps) => {
    const { fmDateValue } = props;
    const { onFMDateChange } = props;

    return (
        <div className={classes.datetimecontrols}>
            <div className={classes.datetimecontrol}>
                <label htmlFor="fmDate">
                    Compare To{' '}
                    <input type="date" id="fmDate" value={fmDateValue} onChange={onFMDateChange} />
                </label>
            </div>
        </div>
    );
};

export default OneDatePicker;
