import { middleware as loginMiddleware, reducers as loginReducers } from '@local/login';
import type { Reducer, Action, EnhancedStore } from '@reduxjs/toolkit';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

const combinedReducer = combineReducers({
    ...loginReducers,
});

const rootReducer: Reducer = (state: RootState, action: Action) => {
    if (action.type === 'store/reset') {
        return undefined;
    }
    return combinedReducer(state, action);
};

export const store: EnhancedStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([...loginMiddleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
