import { getLicenseToken } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
    Title,
    LogarithmicScale,
    TimeScale,
} from 'chart.js';
import { useState, useEffect, useCallback } from 'react';
import { Bar, Line, Scatter } from 'react-chartjs-2';

import type { Model, DropDownItem, InstrumentProps } from '../../components/Helper/datatypes';
import { getErrorMessage } from '../../components/Helper/error';
import { getInstruments } from '../../components/Helper/getInstruments';
import DateTimePicker from '../dataprofile/DateTimePicker';
import Header from '../instruments/Header';
import SubHeader from '../instruments/SubHeader';
import {
    getCSChartData,
    getChartCSBarOptions,
    setColorscale,
    getCSChartDataDiscrete,
} from './ColorscaleFunctions';
import {
    getChartData,
    getChartBarOptions,
    getChartMisfitData,
    getWaterDepthInfo,
    getChartLineOptions,
} from './ModelFunctions';
import classes from './ModelSection.module.css';
import OneDatePicker from './OneDatePicker';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    BarController,
    PointElement,
    LineElement,
    LogarithmicScale,
    TimeScale,
    Title,
);

type DatePickerType = string | number | Date;

function getwaterLayerNoInfo(
    iSelectedInstrumentID: number,
    iInstrumentsInfo: Array<InstrumentProps>,
) {
    let waterLayerInfo = 0;

    for (let i = 0; i < iInstrumentsInfo.length; i += 1) {
        if (iInstrumentsInfo[i].id === iSelectedInstrumentID) {
            waterLayerInfo = iInstrumentsInfo[i].waterTableLayer;
            break;
        }
    }
    return waterLayerInfo;
}

function getInitialFromDate() {
    const fromDateInitial = new Date();
    // Subtract 30 days from today:
    fromDateInitial.setDate(new Date().getDate() - 30);
    // Return today minus 30 days as iso string:
    return fromDateInitial.toISOString();
}

function getyyyyMMdd(dateISOstring: string) {
    if (dateISOstring !== '') {
        const newDate = new Date(dateISOstring);
        // Return date in yyyy-MM-dd format:
        return newDate.toISOString().split('T')[0];
    }
    return new Date().toISOString();
}

let instrumentsInfo: Array<InstrumentProps>;

export const ModelSection = () => {
    const [models, setModels] = useState<Model[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitializing, setIsInitializing] = useState<boolean>(true);

    const [loadError, setLoadError] = useState<string>('');
    const [fromDate, setFromDate] = useState<string>('');
    const [toDate, setToDate] = useState<string>('');
    const [fmDate, setFMDate] = useState<string>('');

    const [selectedInstrumentID, setSelectedInstrumentID] = useState<string>('');
    const [instrumentList, setInstrumentList] = useState<DropDownItem[]>([]);
    const [controller, setController] = useState<AbortController | null>(null);
    const [controllerFirst, setControllerFirst] = useState<AbortController | null>(null);

    const [waterLayerNo, setWaterLayerNo] = useState<number>(0);
    const [firstModel, setFirstModel] = useState<Model>();

    const [token, setToken] = useState<string | null>(null);

    // Get list of instruments - only done once:
    const fetchInstrumentsHandler = async (signal: AbortSignal) => {
        try {
            const fetchedInstruments = await getInstruments({ signal });

            instrumentsInfo = fetchedInstruments;

            const instrList: DropDownItem[] = fetchedInstruments.map((instrument) => ({
                displayName: `${instrument.description} (${instrument.name})`,
                id: String(instrument.id),
            }));

            if (instrList.length > 0) {
                const startFromDate = getInitialFromDate();
                const startToDate = new Date().toISOString();

                // Abort previous fetch if there is one
                if (controller) {
                    controller.abort();
                }
                const newController = new AbortController();
                setController(newController);

                fetchModelsHandler(
                    startFromDate,
                    startToDate,
                    instrList[0].id,
                    newController.signal,
                );

                // Abort previous fetch if there is one
                if (controllerFirst) {
                    controllerFirst.abort();
                }
                const newControllerFirst = new AbortController();
                setControllerFirst(newControllerFirst);

                fetchFirstModelsHandler('', instrList[0].id, newControllerFirst.signal);

                setFromDate(startFromDate);
                setToDate(startToDate);

                setSelectedInstrumentID(instrList[0].id);
                setWaterLayerNo(instrumentsInfo[0].waterTableLayer);
                setInstrumentList(instrList);
                setIsInitializing(false);
            }
        } catch (error: any) {
            if (error.name === 'AbortError') {
                // some comment
            } else {
                setLoadError(error.message);
            }
        }
    };

    const fetchModelsHandler = useCallback(
        async (iFromDate: string, iToDate: string, iInstrumentID: string, signal: AbortSignal) => {
            setIsLoading(true);
            setLoadError(''); // Clear previous errors

            try {
                const idStr = 'id=';
                const startDateStr = '&startDate=';
                const endDateStr = '&endDate=';
                const bodyStr = `${idStr}${iInstrumentID}${startDateStr}${iFromDate}${endDateStr}${iToDate}`;
                setToken(getLicenseToken());
                const response = await fetch(
                    'https://monitoringbackendapi.azurewebsites.net/api/GetModels',
                    {
                        signal,
                        method: 'POST',
                        body: bodyStr,
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                if (!response.ok) {
                    throw new Error('Loading models failed');
                }

                const fetchedModels: Array<Model> = await response.json();
                setModels(fetchedModels);

                // Need to transform fetched data to chartjs datasets...
            } catch (error) {
                setLoadError(getErrorMessage(error));
            }
            setIsLoading(false);
        },
        [token],
    );

    const fetchFirstModelsHandler = useCallback(
        async (iFromDate: string, iInstrumentID: string, signal: AbortSignal) => {
            setIsLoading(true);
            setLoadError(''); // Clear previous errors

            try {
                const idStr = 'id=';
                const startDateStr = '&startDate=';
                let bodyStr = `${idStr}${iInstrumentID}`;
                if (iFromDate !== '') {
                    bodyStr = `${idStr}${iInstrumentID}${startDateStr}${iFromDate}`;
                }

                setToken(getLicenseToken());
                const response = await fetch(
                    'https://monitoringbackendapi.azurewebsites.net/api/GetFirstModel',
                    {
                        signal,
                        method: 'POST',
                        body: bodyStr,
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                if (!response.ok) {
                    throw new Error('Loading first models failed');
                }

                const fetchedFirstModel: Model = await response.json();

                setFirstModel(fetchedFirstModel);
                setFMDate(fetchedFirstModel.measurementTime.toString());

                // Need to transform fetched data to chartjs datasets...
            } catch (error) {
                setLoadError(getErrorMessage(error));
            }
            setIsLoading(false);
        },
        [token],
    );

    useEffect(() => {
        const abortController = new AbortController();

        fetchInstrumentsHandler(abortController.signal);

        return () => {
            abortController.abort();
        };
    }, []);

    const fmDateChangeHandler = useCallback(
        (event: { target: { value: DatePickerType } }) => {
            // Abort previous fetch if there is one
            if (controllerFirst) {
                controllerFirst.abort();
            }
            const newControllerFirst = new AbortController();
            setControllerFirst(newControllerFirst);

            const newFMDate: Date = new Date(event.target.value);
            setFMDate(newFMDate.toISOString());
            fetchFirstModelsHandler(
                newFMDate.toISOString(),
                selectedInstrumentID,
                newControllerFirst.signal,
            );
        },
        [fmDate, selectedInstrumentID, controllerFirst],
    );

    const fromDateChangeHandler = useCallback(
        (event: { target: { value: DatePickerType } }) => {
            // Abort previous fetch if there is one
            if (controller) {
                controller.abort();
            }
            const newController = new AbortController();
            setController(newController);

            const newFromDate: Date = new Date(event.target.value);
            setFromDate(newFromDate.toISOString());
            fetchModelsHandler(
                newFromDate.toISOString(),
                toDate,
                selectedInstrumentID,
                newController.signal,
            );
        },
        [selectedInstrumentID, fromDate, toDate],
    );

    const toDateChangeHandler = useCallback(
        (event: { target: { value: DatePickerType } }) => {
            // Abort previous fetch if there is one
            if (controller) {
                controller.abort();
            }
            const newController = new AbortController();
            setController(newController);

            const newToDate: Date = new Date(event.target.value);

            setToDate(newToDate.toISOString());
            fetchModelsHandler(
                fromDate,
                newToDate.toISOString(),
                selectedInstrumentID,
                newController.signal,
            );
        },
        [selectedInstrumentID, fromDate, toDate],
    );

    const instrumentChangehandler = useCallback(
        (newSelectedId: string) => {
            // Abort previous fetch if there is one
            if (controllerFirst) {
                controllerFirst.abort();
            }
            const newControllerFirst = new AbortController();
            setControllerFirst(newControllerFirst);
            fetchFirstModelsHandler('', newSelectedId, newControllerFirst.signal);

            // Abort previous fetch if there is one
            if (controller) {
                controller.abort();
            }
            const newController = new AbortController();
            setController(newController);

            setSelectedInstrumentID(newSelectedId);
            setWaterLayerNo(getwaterLayerNoInfo(Number(newSelectedId), instrumentsInfo));

            fetchModelsHandler(fromDate, toDate, newSelectedId, newController.signal);
        },
        [selectedInstrumentID, controller, controllerFirst],
    );

    let content = <p>Found no data in the selected time interval.</p>;

    let contentCS = <p>No CS data.</p>;

    let contentMisfit = (
        <div>
            <p>Found no misfit data.</p>
        </div>
    );

    let contentMiddleChart = <div />;

    if (models.length > 0) {
        if (firstModel !== undefined && models !== undefined) {
            if (
                firstModel.resistivities.split(',').length ===
                models[models.length - 1].resistivities.split(',').length
            ) {
                setColorscale('Resistivity');

                const chartData = getChartData(0, models[0], models);
                const chartBarOptions = getChartBarOptions('Models', 'Depth [m]', true, true);

                const chartMisfitData = getChartMisfitData(models);
                const chartScatterOptions = getChartLineOptions(
                    'Residual Chart',
                    'Residual',
                    false,
                );

                const chartCSBarOptions = getChartCSBarOptions(
                    'logarithmic',
                    'Resistivity',
                    'x',
                    5,
                );
                const chartCSData = getCSChartData();

                content = <Bar data={chartData} options={chartBarOptions as any} />;

                contentCS = (
                    <div className={classes.colorscaleFrame}>
                        <Bar data={chartCSData} options={chartCSBarOptions as any} />
                    </div>
                );

                contentMisfit = (
                    <div className={classes.misfitFrame}>
                        <Scatter data={chartMisfitData} options={chartScatterOptions as any} />
                    </div>
                );

                if (waterLayerNo > 0) {
                    const chartWaterData = getWaterDepthInfo(waterLayerNo, models);

                    const chartWaterLineOptions = getChartLineOptions(
                        'Wather Depth Chart',
                        'Depth [m]',
                        false,
                    );

                    contentMiddleChart = (
                        <div>
                            <div className={classes.misfitFrame}>
                                <Line
                                    data={chartWaterData}
                                    options={chartWaterLineOptions as any}
                                />
                            </div>
                        </div>
                    );
                } else {
                    setColorscale('Difference');
                    const chartCSBarOptions2 = getChartCSBarOptions(
                        'linear',
                        'Difference [%]',
                        'y',
                        9,
                    );

                    const chartCSData2 = getCSChartDataDiscrete();

                    const chartDiffData = getChartData(1, firstModel, models);

                    const chartDiffBarOptions = getChartBarOptions(
                        'Resistivity Diff Chart',
                        'Diff [%]',
                        true,
                        true,
                    );

                    contentMiddleChart = (
                        <div>
                            <OneDatePicker
                                onFMDateChange={fmDateChangeHandler}
                                fmDateValue={getyyyyMMdd(fmDate)}
                            />
                            <Bar data={chartDiffData} options={chartDiffBarOptions as any} />
                            <div className={classes.colorscaleFrame}>
                                <Bar data={chartCSData2} options={chartCSBarOptions2 as any} />
                            </div>
                        </div>
                    );
                }
            }
        }
    }

    if (loadError !== '') {
        content = <p>{loadError}</p>;
    }

    if (isLoading) {
        content = <p>Loading data...</p>;
    }

    if (isInitializing) {
        return <p>Loading data...</p>;
    }

    return (
        <>
            <Header />
            <SubHeader
                dropDownItems={instrumentList}
                onSelectedValueChange={instrumentChangehandler}
            />
            <div className={classes.modelplot}>
                {content}
                {contentCS}
                {contentMiddleChart}
                {contentMisfit}
                <DateTimePicker
                    onFromDateChange={fromDateChangeHandler}
                    onToDateChange={toDateChangeHandler}
                    fromDateValue={getyyyyMMdd(fromDate)}
                    toDateValue={getyyyyMMdd(toDate)}
                />
            </div>
        </>
    );
};
