import type { LandingPageProps } from '@local/login';
import { LandingPage, LoginHandler } from '@local/login';
import { setLoginError } from '@local/login/dist/store/sessionStorageHelpers/loginErrorHelper';
import { trackError } from '@local/metrics';
import { Outlet, useLocation, useRoutes } from 'react-router-dom';

import { LogoTitle } from './assets/logoTitle';
import { ContentScaffolding } from './components/contentScaffolding/ContentScaffolding';
import { DataProfile } from './pages/dataprofile/DataProfile';
import { Instruments } from './pages/instruments/Instruments';
import { ModelSection } from './pages/modelsection/ModelSection';
import { store } from './store/store';

export function WrappedLandingPage({ getSignInUrl }: LandingPageProps): JSX.Element {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const error = query.get('error');

    if (error) {
        trackError('Error on login', 'error from login in <App>', {
            error,
            description: query.get('description') || undefined,
        });
        setLoginError(error ? `${'LOGIN_FAILED'} ${'LOG_IN_AGAIN'}` : '');
    }

    return <LandingPage getSignInUrl={getSignInUrl} />;
}

export function AppRoutes(): React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
> | null {
    return useRoutes([
        {
            path: '/',
            element: <Outlet />,
            children: [
                {
                    path: ':orgUuid',
                    element: <ContentScaffolding />,
                    children: [
                        {
                            index: true,
                            path: 'instruments',
                            element: <Instruments />,
                        },
                        {
                            path: 'dataprofile',
                            element: <DataProfile />,
                        },
                        {
                            path: 'modelsection',
                            element: <ModelSection />,
                        },
                    ],
                },
            ],
        },
    ]);
}

function App() {
    return (
        <LoginHandler
            guardedRoutes={<AppRoutes />}
            store={store}
            LandingPage={WrappedLandingPage}
            LandingPageLogo={LogoTitle}
            service="none"
            homePagePath="/instruments/instruments"
            additionalScopes={[]}
        />
    );
}

export default App;
