import type { InstrumentProps } from './datatypes';

export async function getInstruments(options?: { signal: AbortSignal }) {
    try {
        // console.log('getInstruments 1');
        const response = await fetch(
            'https://monitoringbackendapi.azurewebsites.net/api/ListInstruments',
            options,
        );
        // console.log('getInstruments 2');
        if (!response.ok) {
            // console.log('Something went wrong - http');
            throw new Error('Something went wrong - http');
        }
        // console.log('getInstruments 3');
        const fetchedInstruments: Array<InstrumentProps> = await response.json();
        // console.log('getInstruments 4');
        return fetchedInstruments;
    } catch (error) {
        throw new Error('Something went wrong - response');
        // console.log(error.message);
    }
    // console.log(fetchedInstruments);
}
