import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    LogarithmicScale,
    TimeScale,
} from 'chart.js';
import { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';

import type { TemData, DropDownItem } from '../../components/Helper/datatypes';
import { getErrorMessage } from '../../components/Helper/error';
import { getInstruments } from '../../components/Helper/getInstruments';
import Header from '../instruments/Header';
import SubHeader from '../instruments/SubHeader';
import { getChartData, getChartOptions } from './DataFunctions';
import classes from './DataProfile.module.css';
import DateTimePicker from './DateTimePicker';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    TimeScale,
    Title,
);

function getInitialFromDate() {
    const fromDateInitial = new Date();
    // Subtract 30 days from today:
    fromDateInitial.setDate(new Date().getDate() - 30);
    // Return today minus 30 days as iso string:
    return fromDateInitial.toISOString();
}

function getyyyyMMdd(dateISOstring: string) {
    if (dateISOstring !== '') {
        const newDate = new Date(dateISOstring);
        // Return date in yyyy-MM-dd format:
        return newDate.toISOString().split('T')[0];
    }
    return new Date().toISOString();
}

export const DataProfile = () => {
    const [temData, setTemData] = useState<TemData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitializing, setIsInitializing] = useState<boolean>(true);

    const [loadError, setLoadError] = useState<string>('');
    const [fromDate, setFromDate] = useState<string>('');
    const [toDate, setToDate] = useState<string>('');

    const [selectedInstrumentID, setSelectedInstrumentID] = useState<string>('');
    const [instrumentList, setInstrumentList] = useState<DropDownItem[]>([]);

    // // Get list of instruments - only done once:
    const fetchInstrumentsHandler = async () => {
        try {
            const fetchedInstruments = await getInstruments();

            const instrList: DropDownItem[] = fetchedInstruments.map((instrument) => ({
                displayName: `${instrument.description} (${instrument.name})`,
                id: String(instrument.id),
            }));

            if (instrList.length > 0) {
                const startFromDate = getInitialFromDate();
                const startToDate = new Date().toISOString();
                fetchTemDataHandler(startFromDate, startToDate, instrList[0].id);
                setFromDate(startFromDate);
                setToDate(startToDate);
                setSelectedInstrumentID(instrList[0].id);
                setInstrumentList(instrList);
                setIsInitializing(false);
            }
        } catch (error) {
            // console.log(error.message);
        }
    };

    const fetchTemDataHandler = useCallback(
        async (iFromDate: string, iToDate: string, iInstrumentID: string) => {
            setIsLoading(true);
            setLoadError(''); // Clear previous errors

            try {
                const idStr = 'id=';
                const startDateStr = '&startDate=';
                const endDateStr = '&endDate=';
                const bodyStr = `${idStr}${iInstrumentID}${startDateStr}${iFromDate}${endDateStr}${iToDate}`;

                const response = await fetch(
                    'https://monitoringbackendapi.azurewebsites.net/api/GetData',
                    {
                        method: 'POST',
                        body: bodyStr,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );
                if (!response.ok) {
                    throw new Error('Loading data failed');
                }

                const fetchedTemData: Array<TemData> = await response.json();
                setTemData(fetchedTemData);

                // Need to transform fetched data to chartjs datasets...
            } catch (error) {
                setLoadError(getErrorMessage(error));
            }
            setIsLoading(false);
        },
        [],
    );

    useEffect(() => {
        fetchInstrumentsHandler();
    }, []);

    const fromDateChangeHandler = useCallback(
        (event: { target: { value: string | number | Date } }) => {
            const newFromDate: Date = new Date(event.target.value);
            setFromDate(newFromDate.toISOString());
            fetchTemDataHandler(newFromDate.toISOString(), toDate, selectedInstrumentID);
        },
        [fromDate, toDate, selectedInstrumentID],
    );

    const toDateChangeHandler = useCallback(
        (event: { target: { value: string | number | Date } }) => {
            const newToDate: Date = new Date(event.target.value);

            setToDate(newToDate.toISOString());
            fetchTemDataHandler(fromDate, newToDate.toISOString(), selectedInstrumentID);
        },
        [fromDate, toDate, selectedInstrumentID],
    );

    const instrumentChangehandler = (newSelectedId: string) => {
        setSelectedInstrumentID(newSelectedId);
        fetchTemDataHandler(fromDate, toDate, newSelectedId);
    };

    let content = <p>Found no data in the selected time interval.</p>;

    if (temData.length > 0) {
        // transform the fetched data in json format
        const dataForPlot = getChartData(temData);

        const chartOptions = getChartOptions(dataForPlot.channelData[0].channelNumber);
        const chartOptions2 = getChartOptions(dataForPlot.channelData[1].channelNumber);

        content = (
            <>
                {' '}
                <Line options={chartOptions as any} data={dataForPlot.channelData[0]} />{' '}
                <Line options={chartOptions2 as any} data={dataForPlot.channelData[1]} />{' '}
            </>
        );
    }
    if (loadError !== '') {
        content = <p>{loadError}</p>;
    }
    if (isLoading) {
        content = <p>Loading data...</p>;
    }

    if (isInitializing) {
        return <p>Loading data...</p>;
    }

    return (
        <>
            <Header />
            <SubHeader
                dropDownItems={instrumentList}
                onSelectedValueChange={instrumentChangehandler}
            />
            <div className={classes.qcplot}>
                {content}

                <DateTimePicker
                    onFromDateChange={fromDateChangeHandler}
                    onToDateChange={toDateChangeHandler}
                    fromDateValue={getyyyyMMdd(fromDate)}
                    toDateValue={getyyyyMMdd(toDate)}
                />
            </div>
        </>
    );
};
